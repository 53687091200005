import React from 'react'
//import './about.css'
import { useTranslation } from 'react-i18next'

const About = () => {
    const { t, i18n } = useTranslation()
    return(
        <>
        <div className="container-fluid nav-bg" >
                <div className="row">
                    <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto bg-light">
                            <div class="p-1 w-auto h-25 bg-light font-weight-bold"> 
                            {t('about.weare')}
                            </div>
                        </div>
                        <div className="col-12 mx-auto bg-white">
                            <div class="p-3 w-auto h-50 bg-white"> 
                            {t('about.content1')}
                            </div>
                        </div>
                        <div className="col-12 mx-auto bg-white">
                            <div class="p-3 w-auto h-50 bg-white"> 
                            {t('about.content2')}
                            </div>
                        </div>
                        <div className="col-12 mx-auto bg-white">
                            <div class="p-3 w-auto h-50 bg-white"> 
                            {t('about.content3')}
                            </div>
                        </div>
                        <div className="col-12 mx-auto bg-white">
                            <div class="p-3 w-auto h-50 bg-white"> 
                            {t('about.content4')}
                            </div>
                        </div>
                        
                    </div>
                </div>
    </div>
        </>
    );
};
export default About