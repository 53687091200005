import React from 'react';
import PhotoGallery from "react-photo-gallery";
import { useTranslation } from 'react-i18next'

import './styles/Gallery.css'

const Gallery =()=>{

  const { t, i18n } = useTranslation()

  const photos = [
    {}
  ];

    const photos2020 = [
        {
          src: "../images/gallery/Img_100.jpg",
          width: 2,
          height: 1
        },
        {
          src: "../images/gallery/Img_101.jpg",
          width: 1,
          height: 1
        },
        {
          src: "../images/gallery/Img_102.jpg",
          width: 1,
          height: 1
         }
         ,
        {
          src: "../images/gallery/Img_103.jpg",
          width: 2,
          height: 1
        },
        {
          src: "../images/gallery/Img_105.jpg",
          width: 2,
          height: 1
      }
      ,
      {
          src: "../images/gallery/Img_104.jpg",
          width: 2,
          height: 1
      }
        
    ];

    const photos2021 = [
        {
            src: "../images/gallery/2021_Ebanezer_Pkm_1.jpg",
            width: 2,
            height: 1
        }
        ,
        {
            src: "../images/gallery/2021_Ebanezer_Pkm_2.jpg",
            width: 2,
            height: 1
        },
        {
          src: "../images/gallery/2021_Ebanezer_Pkm_3.jpg",
          width: 2,
          height: 1
        },
        {
          src: "../images/gallery/2021_Ebanezer_Pkm_4.jpg",
          width: 2,
          height: 1
        },
        {
          src: "../images/gallery/2021_Ebanezer_Pkm_5.jpg",
          width: 2,
          height: 1
       },
       {
        src: "../images/gallery/2021_Ebanezer_Pkm_6.jpg",
        width: 2,
        height: 1
       },
        {
          src: "../images/gallery/2021_Ebanezer_Pkm_7.jpg",
          width: 2,
          height: 1
        }

    ];

    const photos2021Lent1 = [
      {
          src: "../images/gallery/2021_MaryL_Melur1.jpg",
          width: 2,
          height: 1
      }
      ,
      {
          src: "../images/gallery/2021_MaryL_Melur2.jpg",
          width: 2,
          height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur3.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur4.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur5.jpg",
        width: 2,
        height: 1
     },
     {
      src: "../images/gallery/2021_MaryL_Melur6.jpg",
      width: 2,
      height: 1
     },
      {
        src: "../images/gallery/2021_MaryL_Melur7.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur8.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur9.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur10.jpg",
        width: 2,
        height: 1
      }
      ,
      {
        src: "../images/gallery/2021_MaryL_Melur11.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_MaryL_Melur12.jpg",
        width: 2,
        height: 1
      }


  ];



    const photos2021Lent2 = [
      {
          src: "../images/gallery/2021_CSI_Mandabasalai1.jpg",
          width: 2,
          height: 1
      }
      ,
      {
          src: "../images/gallery/2021_CSI_Mandabasalai2.jpg",
          width: 2,
          height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai3.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai4.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai5.jpg",
        width: 2,
        height: 1
     },
     {
      src: "../images/gallery/2021_CSI_Mandabasalai6.jpg",
      width: 2,
      height: 1
     },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai7.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai8.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai9.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Mandabasalai10.jpg",
        width: 2,
        height: 1
      }


  ];

    const photos2021Lent3 = [
      {
          src: "../images/gallery/2021_CSI_Sayalgudi1.jpg",
          width: 2,
          height: 1
      }
      ,
      {
          src: "../images/gallery/2021_CSI_Sayalgudi2.jpg",
          width: 2,
          height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Sayalgudi3.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Sayalgudi4.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Sayalgudi5.jpg",
        width: 2,
        height: 1
     },
     {
      src: "../images/gallery/2021_CSI_Sayalgudi6.jpg",
      width: 2,
      height: 1
     },
      {
        src: "../images/gallery/2021_CSI_Sayalgudi7.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Sayalgudi8.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Sayalgudi9.jpg",
        width: 2,
        height: 1
      }

  ];


    const photos2021Lent4 = [
      {
          src: "../images/gallery/2021_CSI_Aasur1.jpg",
          width: 2,
          height: 1
      }
      ,
      {
          src: "../images/gallery/2021_CSI_Aasur2.jpg",
          width: 2,
          height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Aasur3.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Aasur4.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Aasur5.jpg",
        width: 2,
        height: 1
     },
     {
      src: "../images/gallery/2021_CSI_Aasur6.jpg",
      width: 2,
      height: 1
     },
      {
        src: "../images/gallery/2021_CSI_Aasur7.jpg",
        width: 2,
        height: 1
      },
      {
        src: "../images/gallery/2021_CSI_Aasur8.jpg",
        width: 2,
        height: 1
      }

  ];

  const photos2021Lent5 = [
    {
        src: "../images/gallery/2021_CSI_Sevalpatti1.jpg",
        width: 2,
        height: 1
    }
    ,
    {
        src: "../images/gallery/2021_CSI_Sevalpatti2.jpg",
        width: 2,
        height: 1
    },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti3.jpg",
      width: 2,
      height: 1
    },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti4.jpg",
      width: 2,
      height: 1
    },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti5.jpg",
      width: 2,
      height: 1
   },
   {
    src: "../images/gallery/2021_CSI_Sevalpatti6.jpg",
    width: 2,
    height: 1
   },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti7.jpg",
      width: 2,
      height: 1
    },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti8.jpg",
      width: 2,
      height: 1
    },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti9.jpg",
      width: 2,
      height: 1
    },
    {
      src: "../images/gallery/2021_CSI_Sevalpatti10.jpg",
      width: 2,
      height: 1
    }


];

    return(
    <> 
        <div className="container-fluid nav-bg" >
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="col-12 mx-auto bg-light">
                                <div class="p-1 w-auto h-25 bg-light "> 
                                <strong>{t('menu.gallery')}</strong>
                                </div>

                                <div class="btn-group">
                                  <button type="button" class="btn btn-info">{t('gallery.year')}</button>
                                  <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="sr-only"></span>
                                  </button>
                                  <div id="divdropdownmenu" class="dropdown-menu">
                                    <a class="dropdown-item" href="#divPhotos2021">2021</a>
                                    <a class="dropdown-item" href="#divPhotos2021Lentweek1">{t('gallery.lent1')}</a>
                                    <a class="dropdown-item" href="#divPhotos2021Lentweek2">{t('gallery.lent2')}</a>
                                    <a class="dropdown-item" href="#divPhotos2021Lentweek3">{t('gallery.lent3')}</a>
                                    <a class="dropdown-item" href="#divPhotos2021Lentweek4">{t('gallery.lent4')}</a>
                                    <a class="dropdown-item" href="#divPhotos2021Lentweek5">{t('gallery.lent5')}</a>
                                    <div class="dropdown-divider"></div>
                                   <a class="dropdown-item" href="#divPhotos2020">2020</a>
                                  </div>
                                </div>

                        </div>
                        <div id="divPhotos2021">
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  2021                         
                            </div>
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  {t('gallery.churchStEbanezerChurchECI')}                       
                            </div>
                          <PhotoGallery photos={photos2021}/>
                        </div>
                        <div id="divPhotos2021Lentweek1">
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  {t('gallery.lent1CSIMelur')}
                            </div>
                            
                          <PhotoGallery photos={photos2021Lent1}/>
                        </div>
                        <div id="divPhotos2021Lentweek2">
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  {t('gallery.lent2CSIMandapasalai')}                       
                            </div>
                          <PhotoGallery photos={photos2021Lent2}/>
                        </div>
                        <div id="divPhotos2021Lentweek3">
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  {t('gallery.lent3CSISayalgudi')}                       
                            </div>
                          <PhotoGallery photos={photos2021Lent3}/>
                        </div>
                        <div id="divPhotos2021Lentweek4">
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  {t('gallery.lent4CSIAsoor')}                       
                            </div>
                          <PhotoGallery photos={photos2021Lent4}/>
                        </div>

                        <div id="divPhotos2021Lentweek5">
                            <div class="p-1 w-auto h-25 bg-light "> 
                                  {t('gallery.lent5CSISevalpatti')}                       
                            </div>
                          <PhotoGallery photos={photos2021Lent5}/>
                        </div>

                        <div id="divPhotos2020">
                          <div class="p-1 w-auto h-25 bg-light "> 
                                2020                                
                          </div>
                          <PhotoGallery photos={photos2020}/>
                        </div>
                        
                    </div>
                </div>
    </div>
    </>
    );
};

export default Gallery;