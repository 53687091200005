
import React from 'react'

import {NavLink } from 'react-router-dom';

import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';

import moment from 'moment'
import { useTranslation } from 'react-i18next'

import './styles/Home.css'

const Home = () => {
    const { t, i18n } = useTranslation()
    return(
        <>
        <div className="container-fluid nav-bg" >
                 <div className="container mt-4" >
                         <div className="row justify-content-center "  >
                             {/* <div>{ { currDate }  }  </div> */}

                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_aboutus rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/about" >
                                            <img  src="../images/home/home_about_550_352.jpg" alt="Card image" class="img-fluid" width="500px;" /> 
                                        </NavLink>
                                     </div>
                                 </div>
                             </div>
                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_ministries rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/ministries" >
                                            <img  src="../images/home/home_ministries_550_352.jpg" alt="Card image" class="img-fluid" width="500px;" /> 
                                        </NavLink>                                  
                                     </div>
                                 </div>
                             </div>
                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_contactus rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/contact" >
                                            <img  src="../images/home/home_contact_550_352.jpg" alt="Card image" class="img-fluid" width="500px;"  /> 
                                        </NavLink>  
                                     </div>
                                 </div>
                             </div>
                             <div style={{padding:"5px"}}>
                                 <div className="border bcolor_gallery rounded">
                                     <div style={{padding:"10px"}}>
                                        <NavLink to="/gallery" >
                                            <img  src="../images/home/home_galary_550_352.jpg" alt="Card image" class="img-fluid" width="500px;"  /> 
                                        </NavLink>
                                     </div>
                                 </div>
                             </div>
                             
                         </div>
                  </div> 
            </div>
        </>
    );
};
export default Home
