import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './styles/CarouselOverrides.css';


export default function CarouselComponent() {
    return (
        <div className="container-fluid nav-bg" >
            <div className="col-14 mx-auto">
        <div className="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} >
                <div>
                    <img src="../images/carousel/Banner1.jpg" />
                </div>
                <div>
                    <img src="../images/carousel/Banner2.jpg" />
                </div>
                <div>
                    <img src="../images/carousel/Banner3.jpg" />
                </div>
            </Carousel>
        </div>
        </div>
        </div>
    );
}