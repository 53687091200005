import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';

import { useTranslation } from 'react-i18next'


const Contact =()=>{

    const { t, i18n } = useTranslation()

    return(
    <> 
             <div className="container-fluid nav-bg" >
                <div className="row">
                <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto bg-light">
                            <div class="p-1 w-auto h-25 bg-light font-weight-bold"> 
                            {t('menu.contact')}
                            </div>
                        </div>
                </div>

                    <div className="col-12 mx-auto">
                        <div className="container mt-4" >
                            <div className="row justify-content-center " >
                                <div >
                                    <Card className="border border-info"  >
                                        <CardTitle className="p-3 bg-info text-white font-weight-bold">{t('contact.contact')}</CardTitle>
                                        <CardImg top width="100%"  src="../images/contact/contact_numbers.png" alt="Card image cap" />
                                        <CardBody className="bg-info text-white">
                                            <CardText>{t('contact.mobile')} {t('contact.mobileno')} </CardText>
                                            <CardText>{t('contact.whatsapp')} {t('contact.whatsappno')} </CardText>
                                            <CardText>{t('contact.email')} {t('contact.emailID')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</CardText>
                                            <CardText>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div >
                                    <Card className="border  border-success"  >
                                        <CardTitle className="p-3 bg-success text-white font-weight-bold">{t('contact.address')}</CardTitle>
                                        <CardImg top width="100%"  src="../images/contact/contact_home.png" alt="Card image cap" />
                                        <CardBody className="bg-success text-white">
                                            <CardText>{t('contact.name')}</CardText>
                                            <CardText>{t('contact.streetname')}</CardText>
                                            <CardText>{t('contact.area')} </CardText>
                                            <CardText>{t('contact.village')} </CardText>
                                            <CardText>{t('contact.city')} {t('contact.pincode')}</CardText>
                                           
                                        </CardBody>
                                    </Card>
                                </div>
                                <div >
                                    <Card className="border border-primary"  >
                                        <CardTitle className="p-3 bg-primary text-white ">{t('contact.24/7PrayerRequest')}</CardTitle>
                                        <CardImg top width="100%"  src="../images/contact/contact_24_7.png" alt="Card image cap" />
                                        <CardBody className="bg-primary text-white">
                                            {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                                            <CardText>{t('contact.mobile')} {t('contact.mobileno')} </CardText>
                                            <CardText>{t('contact.whatsapp')} {t('contact.whatsappno')} </CardText>
                                            {/* <CardText>{t('contact.email')} {t('contact.contact')}</CardText> */}
                                            <CardText>&nbsp;</CardText>
                                            <CardText>&nbsp;</CardText>
                                            <CardText>&nbsp;</CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="p-3"></div>
                            <div className="row justify-content-center " >

                            <div class="card text-center w-70 border border-secondary" >
                                <div class="card-header bg-light text-dark">
                                    <p>{t('contact.supported')} </p>
                                    {t('contact.bannersupported')}
                                </div>
                                <div >
                                <img src="../images/contact/D_150x150.jpg"  class="rounded-circle" ></img>
                                <img src="../images/contact/M_150x150.jpg"  class="rounded-circle" ></img>
                                   
                                </div>
                                <div class="card-footer  bg-light text-secondary">
                                {t('contact.feedback')}
                                </div>
                            </div>
                               
                            </div>
                            
                            <div className="p-3"></div>

                        </div>

                    </div>
                </div>
            </div>
    </>
    );
};

export default Contact;