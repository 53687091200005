import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';

import { useTranslation } from 'react-i18next'

import './styles/Committee.css'

const Committee =()=>{

    const { t, i18n } = useTranslation()

    return(
    <> 
        <div className="container-fluid nav-bg" >
            <div className="row">
                <div className="col-12 mx-auto">
                    <div className="container mt-4" >
                        <div className="row justify-content-center " >
                            <div class="card text-center  border border_Founder" >
                                <div >
                                        <img src="../images/committee/Charles.jpg"  class="rounded-circle" ></img>
                                </div>

                                <div class="card-footer  bgColor_Founder text-white">
                                       <p> {t('committee.foundername')}</p>
                                        {t('committee.founder')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mx-auto">
                            <div className="container mt-4" >
                                <div className="row justify-content-center " >
                                    <div class="card text-center  border border_member" >
                                        <div >
                                                <img src="../images/committee/Rooban.jpg"  class="rounded-circle" ></img>
                                        </div>

                                        <div class="card-footer  bgColor_member text-white">
                                                <p>{t('committee.secretaryname')}</p>
                                                {t('committee.secretary')}

                                        </div>
                                    </div>

                                    <div class="card text-center  border border_member" >
                                        <div >
                                                <img src="../images/committee/Vinoth.jpg"  class="rounded-circle" ></img>
                                        </div>

                                        <div class="card-footer  bgColor_member text-white">
                                                <p>{t('committee.coordinator1')}</p>
                                                {t('committee.coordinator')}
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default Committee