import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import {NavLink } from 'react-router-dom';

import { shareTextToWhatsApp } from 'share-text-to-whatsapp';
import {isMobileOnly} from 'react-device-detect';

import CopyToClipboard from 'react-copy-to-clipboard'

import './styles/DailyVerses.css'

const DailyVerses = () => {
    const { t, i18n } = useTranslation()
    const whatsappTodaysverse = t('Todaysverse');
    const domainName="%0Ahttp://adonaiblessings.in";

    const title = "%0A%0A" + t('menu.title');
    const name = "%0A" +t('footer.name');
    const PrayerRequest = "%0A" +t('footer.24/7PrayerRequest');
    const mobileno = "%0A" +t('footer.mobileno');

    function sendWhatsApp() {
        
        if (!isMobileOnly)
        {
            //web.whatpp
            window.open("https://web.whatsapp.com/send?text="+ t( ''+moment().utcOffset("+05:30").format("DDMM")+'' ) + title + name + PrayerRequest + mobileno + domainName);
        }
        else
        {
            //whatsapp
            window.open("https://api.whatsapp.com/send?text="+ t( ''+moment().utcOffset("+05:30").format("DDMM")+'' ) + title + name + PrayerRequest + mobileno + domainName);
        } 
    }


    return(
        <>
          <div className="container-fluid nav-bg" >
          <div className="row ">
          <div  className="col-12 mx-auto container pt-1 pb-2" >
          <div class="border rounded bordercolor_DailyVerses" >
                <div class="text-white font-weight-bold d-flex justify-content-center bgcolor_DailyVerses " style={{height: '2rem'}}>
                    {t('Todaysverse')}
                </div>
                <div className="div_whatsapp">
                <NavLink to="#">
                    <CopyToClipboard text={ t( ''+moment().utcOffset("+05:30").format("DDMM")+'' ) } >
                        <img  src="../images/dailyverses/copy_dailyverses.jpg"   className="pr-1" /> 
                    </CopyToClipboard>
                </NavLink>
                <NavLink to="#" >
                    <img  src="../images/dailyverses/share_dailyverses.jpg"  class="rounded-circle" onClick={sendWhatsApp} /> 
                </NavLink>
                </div>
                <div class="d-flex justify-content-center p-2 fontcolor_DailyVerses font-weight-bold"> 
                    {t( ''+moment().utcOffset("+05:30").format("DDMM")+'' )} 
                </div>

                <div class="d-flex  fontcolor_DailyVerses font-weight-bold"> 
                    {t( 'menu.title' )} 
                </div>
                <div class="d-flex  fontcolor_DailyVerses font-weight-bold"> 
                    {t( 'footer.name' )} 
                </div>
                <div class="d-flex  fontcolor_DailyVerses font-weight-bold"> 
                    {t( 'footer.24/7PrayerRequest' )} 
                </div>
                <div class="d-flex  fontcolor_DailyVerses font-weight-bold"> 
                    {t( 'footer.mobileno' )} 
                </div>
            </div>
        </div>
        </div>


            {/* <div className="row ">
                        <div  className="col-12 mx-auto container pt-3 pb-3" >
                        <div className="col-12 mx-auto  border rounded" className="bgcolor_DailyVerses">
                            <div class="w-auto h-25  font-weight-bold d-flex justify-content-center" className="bgcolor_DailyVerses" >
                                    Today's verses
                            </div>
                        
                            <div className="col-12 mx-auto d-flex justify-content-center ">
                                <div class="w-auto h-auto  font-weight-bold ">
                                {t( ''+moment().format("DDMM")+'' )} 
                                </div>
                            </div>
                        </div>
                        </div>
            </div> */}
        </div>
        </>
        );
};
export default DailyVerses