

import Navbarcustom from './Navbarcustom';
import React , { Component, Suspense } from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import Home from './Home';
import About from './About';
import Ministries from './Ministries';
import Gallery from './Gallery'
import Contact from './Contact'
import Committee from './Committee'


import Navbarboostrap from "./Navbarboostrap"
import CarouselComponent from "./carouselcomponent"
import Footer from './Footer';
import './i18n';
import DailyVerses from "./DailyVerses"

import './styles/App.css';



function App(props) {

  return (
      <>
      <Suspense fallback={null}>
        {/* <Navbarboostrap/> */}
        <Navbarcustom/> 
        <CarouselComponent/>
        <DailyVerses/>
        <Switch>
              <Route exact path="/" component={Home}/>
              <Route exact path="/About" component={About}/>
              <Route exact path="/Ministries" component={Ministries}/>
              <Route exact path="/Gallery" component={Gallery}/>
              <Route exact path="/Contact" component={Contact}/>
              <Route exact path="/Committee" component={Committee}/>
              <Redirect to="/" />
          </Switch>
           <Footer/> 
        </Suspense>
        </>
      
    
  );
}

export default App;
