import React from 'react';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';

import "./styles/Ministries.css"
import { useTranslation } from 'react-i18next'

const Ministries =()=>{

    const { t, i18n } = useTranslation()


    return(
    <> 

        <div className="container-fluid nav-bg" >
                <div className="row">
                <div className="col-12 mx-auto container">
                        <div className="col-12 mx-auto bg-light">
                            <div class="p-1 w-auto h-25 bg-light font-weight-bold"> 
                            {t('ministries.weare')}
                            </div>
                        </div>
                </div>
                <div className="container mt-4" >
                
                    <div className="row justify-content-center "  >
                            <div  >
                            <Card className="border border-primary" style={{height: '25rem'}}>
                                <CardTitle className="p-3 bg-primary text-white font-weight-bold">{t('ministries.preach')}</CardTitle>
                                <CardImg top width="100%" src="../images/ministries/bible-preaching.jpg" alt="Card image cap" />
                                <CardBody>
                                <CardText >{t('ministries.preachingverses')}</CardText> 
                                </CardBody>
                            </Card>
                            </div>

                            <div>
                            <Card  className="border border-danger " style={{height: '25rem'}} >
                                <CardTitle className="p-3 mb-2 bg-danger text-white font-weight-bold">{t('ministries.poor')}</CardTitle>
                                <CardImg top width="100%" src="../images/ministries/HelpingHand.jpg" alt="Card image cap" />
                                <CardBody>
                                <CardText>{t('ministries.poorverses')} </CardText>
                                </CardBody>
                            </Card>
                            </div>
                            <div>
                            <Card className="border border-success" style={{height: '25rem'}} >
                                <CardTitle className="p-3 mb-2 bg-success text-white font-weight-bold"><CardText>{t('ministries.orphans')}</CardText></CardTitle>
                                <CardImg top width="100%" src="../images/ministries/HelpingOrphans.jpg" alt="Card image cap" />
                                <CardBody>
                                <CardText >{t('ministries.orphansverses')}</CardText>
                                </CardBody>
                            </Card>
                            </div>
                            <div>
                            <Card className="border border-info" style={{height: '25rem'}}>
                                <CardTitle className="p-3 mb-2 bg-info text-white font-weight-bold">{t('ministries.hunger')}</CardTitle>
                                <CardImg top width="100%" src="../images/ministries/HelpingPoor.jpg" alt="Card image cap" />
                                <CardBody>
                            <CardText>{t('ministries.hungerverses')}</CardText>
                                </CardBody>
                            </Card>
                            </div>
                            <div>
                            <Card  className="border border-warning" style={{height: '25rem'}}>
                                <CardTitle className="p-3 mb-2 bg-warning text-white font-weight-bold">{t('ministries.widows')}</CardTitle>
                                <CardImg top width="100%" src="../images/ministries/HelpingWindows.jpg" alt="Card image cap" />
                                <CardBody>
                                <CardText>{t('ministries.widowsverses')}</CardText>
                                </CardBody>
                            </Card>
                            </div>
                
                        </div>
                    </div>
                </div>
         </div>
        </>
    );
};

export default Ministries;