import React, {UseContext} from 'react'
//import Component from 'react-components'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
  } from "react-router-dom";
  import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
  import Home from './Home';
  import About from './About';

  import {NavLink } from 'react-router-dom';


function Navbarboostrap  ()  {

    function handleCollapse (){
        console.log("handleCollapse");
        var nav = document.getElementById("navbarNav");
        var btn = document.getElementById("navbarBtn");
        nav.classList.remove("show");
        btn.classList.add("collapsed");
      };

    return (
        <nav className="navbar navbar-expand-md navbar-light fixed-top">
          {/* <Link className="navbar-brand" to="/index">
            <img src="/x.png" className="d-inline-block align-top" loading="lazy"></img>
            PPlan
          </Link> */}
          <button
            className="navbar-toggler" id="navbarBtn" type="button" 
            data-target="#navbarNav" data-toggle="collapse" 
            aria-controls="navbarNav" aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
    
          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="navbar-nav mr-auto">
              <NavLink to="/1" className="nav-item nav-link" onClick={handleCollapse}>
                User
              </NavLink>
              <NavLink to="/2" className="nav-item nav-link" onClick={handleCollapse}>
                PrjPlan
              </NavLink>
            </div>
          </div>
        </nav>
      );
}

export default Navbarboostrap;
