import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup'
import './styles/Footer.css'
import { useTranslation } from 'react-i18next'

const Footer =()=>{

    const { t, i18n } = useTranslation()

    const changeLanguage = (event) => {
      i18n.changeLanguage(event.target.value)
    }

    return(
    <> 
    <div className="container-fluid nav-bg" >
        <div className="col-12 mx-auto bg-secondary">
        <div class="p-3 w-auto h-25 bg-secondary"> </div>
        </div>
     </div>
      <div className="container-fluid nav-bg " >
            <div className="col-12 mx-auto footer-div-bgcolor">
                    {/* <div className="col-12 mx-auto bg-secondary"> */}
                        
                        <div class="p-3   mb-2 container footer-font">
                        
                            <div className="row  h-100 justify-content-center" >
                                    <div class="p-3  footer-div-list" >
                                        <h5 className="footer-div-content-title">{t('footer.contact')}</h5>
                                        <h6 className="footer-div-content-item">{t('footer.mobile')} {t('footer.mobileno')} </h6>   
                                        <h6 className="footer-div-content-item">{t('footer.whatsapp')} {t('footer.whatsappno')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>  
                                        <h6 className="footer-div-content-item">{t('footer.email')} {t('contact.emailID')} </h6>    
                                    </div>
                                    
                                    <div class="p-3 footer-div-list" >
                                        <h5 className="footer-div-content-title">{t('footer.24/7PrayerRequest')}</h5> 
                                        <h6 className="footer-div-content-item">{t('footer.mobile')} {t('footer.mobileno')}</h6>   
                                        <h6 className="footer-div-content-item">{t('footer.whatsapp')} {t('footer.whatsappno')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>  
                                    </div>
                                   
                                    <div class="p-3 footer-div-list" >
                                    <h5 className="footer-div-content-title">{t('footer.address')}</h5>
                                            <h6 className="footer-div-content-item">{t('footer.name')}</h6>
                                            <h6 className="footer-div-content-item">{t('footer.streetname')}</h6>  
                                            <h6 className="footer-div-content-item">{t('footer.area')}</h6>  
                                            <h6 className="footer-div-content-item">{t('footer.village')} {t('footer.city')} {t('footer.pincode')}</h6>
                                            
                                    </div>
                                   
                                   

                            </div>
                        </div>
                    </div>
                {/* </div> */}
    </div>
    </>
    );
};

export default Footer;